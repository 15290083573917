import React from 'react'
import { useState, useEffect } from 'react';



export function useIsElementVisible(elementId) {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const element = document.getElementById(elementId);
        if (element && isElementInViewport(element)) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }

    const isElementInViewport = (el) => {
        const position = el.getBoundingClientRect();

        return (
            position.top >= 0 &&
            position.left >= 0 &&
            position.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            position.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [elementId]);

    return isVisible;
}

export default useIsElementVisible;