import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import useIsElementVisible from "../../utils/customFunctionViewport"
import GetImgUrl from "../../utils/customFunctions";
import "./provider-carousel.scss";

//quote banner icons
import car from "../../assets/ti_car_block.svg";
import van from "../../assets/ti_van_block.svg";
import bike from "../../assets/ti_bike_block.svg";
import motorhome from "../../assets/ti_motorhome_block.svg";
import caravan from "../../assets/ti_caravan_block.svg";
import car_hover from "../../assets/ti_hovercar_block.svg";
import van_hover from "../../assets/ti_hovervan_block.svg";
import bike_hover from "../../assets/ti_hoverbike_block.svg";
import motorhome_hover from "../../assets/ti_hovermotorhome_block.svg";
import caravan_hover from "../../assets/ti_hovercaravan_block.svg";

//popup
import car_logo from "../../assets/ti_car_logo.svg";
import van_logo from "../../assets/ti_van_logo.svg";
import bike_logo from "../../assets/ti_bike_logo.svg";
import motorhome_logo from "../../assets/ti_motorhome_logo.svg";
import caravan_logo from "../../assets/ti_caravan_logo.svg";
import carPopup from "../../assets/ti_car_white.svg";
import vanPopup from "../../assets/ti_van_white.svg";
import bikePopup from "../../assets/ti_bike_white.svg";
import motorhomePopup from "../../assets/ti_motorhome_white.svg";
import caravanPopup from "../../assets/ti_caravan_white.svg";
import car_pink_popup from "../../assets/ti_car_pink.svg";
import van_pink_popup from "../../assets/ti_van_pink.svg";
import bike_pink_popup from "../../assets/ti_bike_pink.svg";
import motorhome_pink_popup from "../../assets/ti_motorhome_pink.svg";
import caravan_pink_popup from "../../assets/ti_caravan_pink.svg";

//fur
import pink_fur from "../../assets/ti_fur_pink.svg";
import blue_fur from "../../assets/ti_fur_blue.svg";

const ProviderCarousel = ({ images, title, subtitle, ctaUrl, ctaLabel, backgroundColor, enableMask, imagesWhiteBg, imageType }) => {

  const data = useStaticQuery(graphql`
  {
      strapiSiteSettings {
          CarQuoteLink
          VanQuoteLink
          MotorbikeQuoteLink
          MotorhomeQuoteLink
          CaravanQuoteLink
          LayoutTheme
      }
  }
  `)


  // imageType = "IconCTA"
  // console.log(imageType)

  // const isVisible = useIsElementVisible('title');

  const [switchImage, setSwitchImage] = useState(false)
  const [product, setProduct] = useState(null)

  const onHover = (element) => {
    setProduct(element)
    setSwitchImage(true);
  };
  const onLeave = () => {
    setProduct(null)
    setSwitchImage(false);
  };

  const [showPopup, setShowPopup] = useState('')
  const [insurerURL, setInsurerURL] = useState('')

  const [switchImagePopup, setSwitchImagePopup] = useState(false)

  const onHoverPopup = () => {
    setSwitchImagePopup(true);
  };
  const onLeavePopup = () => {
    setSwitchImagePopup(false);
  };

  const handleClick = (e, product) => {
    e.preventDefault(); 
    setShowPopup(product);
  }

  return (
    <>
      <div className={`provider-container 

      ${backgroundColor == "grey" ? ' grey-provider-bg ' : ''} 
      ${backgroundColor == "white" ? ' white-provider-bg ' : ''} 
      ${backgroundColor == "blue" ? ' blue-provider-bg ' : ''} 
      ${backgroundColor == "pink" ? ' pink-provider-bg ' : ''}

      padding-top padding-bottom lateral-padding`}>

        <div className="provider-content">

          <div uk-scrollspy="cls: uk-animation-slide-bottom; target: .provider-carousel-title; delay: 500; repeat: true">
            <h2 className="provider-carousel-title">{title}</h2>
          </div>


          {
            !imageType || imageType == "Empty" ?
              <div className="provider-images">
                {images.map((item, index) => {

                  return (
                    //<div uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .transition-card; delay: 300; repeat: true">
                    <div className={imagesWhiteBg == false ? "ti-provider-single-img transition-card" : "provider-single-image-container transition-card"} key={index}>
                      {" "}
                      <GatsbyImage
                        image={item.gatsbyImageData}
                        alt={""}
                        className="provider-single-image"
                        key={index}
                      />
                    </div>
                    //</div>
                  );
                })}
              </div>
              :
              data.strapiSiteSettings.LayoutTheme == "TI" ?
                <div className="provider-images">
                  <div className="quotes-container" data-uk-grid>
                    <div className="quote-section" onMouseEnter={() => onHover('car')} onMouseLeave={onLeave}>
                      <a href={data.strapiSiteSettings.CarQuoteLink}>
                        <img src={switchImage && product == 'car' ? car_hover : car} className="quote-icon" />
                        {/* <p>Car</p> */}
                      </a>
                    </div>
                    <div className="quote-section" onMouseEnter={() => onHover('van')} onMouseLeave={onLeave}>
                      <a href="#" onClick={(e) => { handleClick(e, 'van'); setInsurerURL(data.strapiSiteSettings.VanQuoteLink) }}>
                        <img src={switchImage && product == 'van' ? van_hover : van} className="quote-icon" />
                        {/* <p>Van</p> */}
                      </a>
                    </div>
                    <div className="quote-section" onMouseEnter={() => onHover('bike')} onMouseLeave={onLeave}>
                      <a href="#" onClick={(e) => { handleClick(e, 'bike'); setInsurerURL(data.strapiSiteSettings.MotorbikeQuoteLink) }}>
                        <img src={switchImage && product == 'bike' ? bike_hover : bike} className="quote-icon" />
                        {/* <p>Motorbike</p> */}
                      </a>
                    </div>
                    <div className="quote-section" onMouseEnter={() => onHover('motorhome')} onMouseLeave={onLeave}>
                      <a href="#" onClick={(e) => { handleClick(e, 'motorhome'); setInsurerURL(data.strapiSiteSettings.MotorhomeQuoteLink) }}>
                        <img src={switchImage && product == 'motorhome' ? motorhome_hover : motorhome} className="quote-icon" />
                        {/* <p>Motorhome</p> */}
                      </a>
                    </div>
                    <div className="quote-section" onMouseEnter={() => onHover('caravan')} onMouseLeave={onLeave}>
                      <a href="#" onClick={(e) => { handleClick(e, 'caravan'); setInsurerURL(data.strapiSiteSettings.CaravanQuoteLink) }}>
                        <img src={switchImage && product == 'caravan' ? caravan_hover : caravan} className="quote-icon" />
                        {/* <p>Caravan</p> */}
                      </a>
                    </div>
                  </div>
                </div>
                : ""
          }

          {subtitle ? <p className="provider-carousel-subtitle">{subtitle}</p> : ''}
          {
            ctaUrl ?
              <div className="provider__cta">
                <a href={ctaUrl} className="cta">
                  {ctaLabel}
                </a>
              </div>
              : ''
          }
        </div>
      </div>

      {
        enableMask ?
          enableMask == "Pink" ?
            <div className="pink-fur">
              <img src={pink_fur} />
            </div>
            :
            enableMask == "Blue" ?
              <div className="blue-fur">
                <img src={blue_fur} />
              </div>
              : ""
          : ""
      }

      {showPopup != '' ?
        <div className="popup-overlay-for-ti" uk-scrollspy="cls: uk-animation-fade; target: .popup; delay: 10; repeat: true">
          <div className="popup">
            <span className="close" onClick={() => setShowPopup('')}>&times;</span>
            <div className="popup-content">
              <img className="insurer-logo"
                src={showPopup == "car" ? car_logo :
                  showPopup == "van" ? van_logo :
                    showPopup == "bike" ? bike_logo :
                      showPopup == "motorhome" ? motorhome_logo :
                        showPopup == "caravan" ? caravan_logo : ""} />

              <p>You are about to go to The
                {showPopup == "car" ? " Car " :
                  showPopup == "van" ? " Van " :
                    showPopup == "bike" ? " Bike " :
                      showPopup == "motorhome" ? " Motorhome " :
                        showPopup == "caravan" ? " Caravan " : ""}
                Insurer</p>

              <a className="cta" href={insurerURL != '' ? insurerURL : ""} onMouseEnter={onHoverPopup} onMouseLeave={onLeavePopup}>
                <img
                  className="mini-logo"
                  src={(showPopup == "car" && switchImagePopup) ? car_pink_popup :
                    showPopup == "car" ? carPopup :
                      (showPopup == "van" && switchImagePopup) ? van_pink_popup :
                        showPopup == "van" ? vanPopup :
                          (showPopup == "bike" && switchImagePopup) ? bike_pink_popup :
                            showPopup == "bike" ? bikePopup :
                              (showPopup == "motorhome" && switchImagePopup) ? motorhome_pink_popup :
                                showPopup == "motorhome" ? motorhomePopup :
                                  (showPopup == "caravan" && switchImagePopup) ? caravan_pink_popup :
                                    showPopup == "caravan" ? caravanPopup : ""} />
                <b>Let's go!</b>
              </a>
              <p className="span-text" onClick={() => setShowPopup('')}>Stay at The Insurers</p>
            </div>
          </div>
        </div>
        : ""
      }
    </>
  );
};

export default ProviderCarousel;
